<template>
  <b-modal
    id="modalTraineeUserDetails"
    :visible="modalVisibility(modalId)"
    :title="`Trainee User Details (${textApproveStatus(
      form.approve_status_id
    )})`"
    @show="onShow()"
    @hidden="resetModal()"
    @ok="onOk"
    size="md"
    ok-only
    no-close-on-backdrop
    ok-title="Done"
  >
    <div>
      <b-row>
        <b-col>
          <base-input
            :disabled="readOnly"
            v-model="form.trainee_fullname"
            label="Full Name"
            :validator="$v.form.trainee_fullname"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            :disabled="readOnly"
            v-model="form.trainee_username"
            label="Username"
            :validator="$v.form.trainee_username"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            :disabled="readOnly"
            v-model="form.trainee_email"
            label="Email"
            :validator="$v.form.trainee_email"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            :disabled="readOnly"
            v-model="form.trainee_ic_number"
            label="IC Number"
            :validator="$v.form.trainee_ic_number"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            :disabled="readOnly"
            v-model="form.trainee_phone_number"
            label="Phone Number"
            :validator="$v.form.trainee_phone_number"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <!--      <b-row>-->
      <!--        <b-col>-->
      <!--          <base-password-->
      <!--              :disabled="readOnly"-->
      <!--            v-model="form.trainee_password"-->
      <!--            label="Password"-->
      <!--            :validator="$v.form.trainee_password"-->
      <!--            :messages="localMessages"-->
      <!--          ></base-password>-->
      <!--        </b-col>-->
      <!--      </b-row>-->
      <!--      <b-row>-->
      <!--        <b-col>-->
      <!--          <base-password-->
      <!--            v-model="form.trainee_password_confirmation"-->
      <!--            label="Confirm Password"-->
      <!--            :validator="$v.form.trainee_password_confirmation"-->
      <!--            :messages="localMessages"-->
      <!--          ></base-password>-->
      <!--        </b-col>-->
      <!--      </b-row>-->
    </div>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
import { email, required, sameAs } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  components: {},
  data() {
    return {
      readOnly: true,
      modalId: "modalTraineeUserDetails",
      isBusy: true,
      form: {
        trainee_username: "",
        trainee_email: "",
        trainee_ic_number: "",
        trainee_phone_number: "",
        trainee_password: "",
        trainee_password_confirmation: "",
      },
    };
  },
  validations: {
    form: {
      trainee_username: { required },
      trainee_email: { required, email },
      trainee_phone_number: { required },
      trainee_ic_number: { required },
      trainee_password: { required },
      trainee_password_confirmation: {
        sameAsPassword: sameAs("trainee_password"),
      },
    },
  },
  computed: {},
  methods: {
    textApproveStatus(id) {
      let txt;
      switch (id) {
        case 1:
          txt = "Approved";
          break;
        case 2:
          txt = "Pending";
          break;
        case 3:
          txt = "Rejected";
          break;
      }
      return txt;
    },
    onShow() {
      console.log("showing");
      console.log(this.$parent.selectedTrainee);
      this.form = this.$parent.selectedTrainee;
    },
    onOk(bvModalEvent) {
      console.log(bvModalEvent);
      bvModalEvent.preventDefault();

      this.$store.dispatch(SET_MODAL_STATE, null);
    },
    resetModal() {
      this.$v.form.$reset();
      this.$store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
</script>

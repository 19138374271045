<template>
  <div>
    <KTCard title="List">
      <template v-slot:toolbar>
        <b-button
          @click="openModal('modalAddTraineeUser')"
          variant="primary"
          size="sm"
          ><i class="fa fa-plus"></i> Add Trainee User</b-button
        >
      </template>

      <template v-slot:body>
        <b-tabs content-class="mt-3">
          <!--pending-->
          <b-tab title="Pending" @click="tabChange" active>
            <b-row v-if="!!tableSettings">
              <b-col cols="6">
                <b-form-group class="m-2" label-size="sm">
                  <template v-if="tableSettings.refresh">
                    <b-button size="sm" class="mr-2">
                      <i class="fa fa-sync"></i> Refresh
                    </b-button>
                  </template>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  class="m-2"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                >
                  <template v-if="tableSettings.filter">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter.firstTab"
                        type="search"
                        placeholder="Type to Search"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          :disabled="!filter.firstTab"
                          @click="filter.firstTab = ''"
                          >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :per-page="tableSettings.perPage"
              :current-page="tableSettings.currentPage"
              :filter="filter.firstTab"
              :busy="isBusy"
              striped
              bordered
              show-empty
              hover
              :items="PendingTraineeUserList"
              :fields="fields"
            >
              <template v-slot:cell(actions)="row">
                <b-button-group size="sm">
                  <b-button
                    @click="onApprove(row.item)"
                    variant="primary"
                    size="sm"
                  >
                    Approve
                  </b-button>
                  <b-button
                    @click="onReject(row.item)"
                    variant="danger"
                    size="sm"
                  >
                    Reject
                  </b-button>

                  <b-button
                    @click="onViewDetails(row.item)"
                    variant="info"
                    size="sm"
                  >
                    View
                  </b-button>
                </b-button-group>
              </template>
            </b-table>

            <b-pagination
              v-if="tableSettings.row > tableSettings.perPage"
              class="pl-0"
              v-model="currentPage"
              :total-rows="tableSettings.rows"
              :per-page="tableSettings.perPage"
            ></b-pagination>
          </b-tab>
          <!--pending-->

          <!--approved-->
          <b-tab @click="tabChange" title="Approved">
            <b-row v-if="!!tableSettings">
              <b-col cols="6">
                <b-form-group class="m-2" label-size="sm">
                  <template v-if="tableSettings.refresh">
                    <b-button size="sm" class="mr-2">
                      <i class="fa fa-sync"></i> Refresh
                    </b-button>
                  </template>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  class="m-2"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                >
                  <template v-if="tableSettings.filter">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter.secondTab"
                        type="search"
                        placeholder="Type to Search"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          :disabled="!filter.secondTab"
                          @click="filter.secondTab = ''"
                          >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :per-page="tableSettings.perPage"
              :current-page="tableSettings.currentPage"
              :filter="filter.secondTab"
              :busy="isBusy"
              striped
              bordered
              show-empty
              hover
              :items="ApprovedTraineeUserList"
              :fields="fields"
            >
              <template v-slot:cell(actions)="row">
                <b-button-group size="sm">
                  <!--                  <b-button-->
                  <!--                    @click="onPermission(row.item)"-->
                  <!--                    variant="primary"-->
                  <!--                    size="sm"-->
                  <!--                  >-->
                  <!--                    Permissions-->
                  <!--                  </b-button>-->
                  <!--                  <b-button-->
                  <!--                    @click="onRemove(row.item)"-->
                  <!--                    variant="danger"-->
                  <!--                    size="sm"-->
                  <!--                  >-->
                  <!--                    Remove-->
                  <!--                  </b-button>-->

                  <b-button
                    @click="onViewDetails(row.item)"
                    variant="info"
                    size="sm"
                  >
                    View
                  </b-button>
                </b-button-group>
              </template>
            </b-table>

            <b-pagination
              v-if="tableSettings.row > tableSettings.perPage"
              class="pl-0"
              v-model="currentPage"
              :total-rows="tableSettings.rows"
              :per-page="tableSettings.perPage"
            ></b-pagination>
          </b-tab>
          <!--approved-->

          <!--rejected-->
          <b-tab @click="tabChange" title="Rejected">
            <b-row v-if="!!tableSettings">
              <b-col cols="6">
                <b-form-group class="m-2" label-size="sm">
                  <template v-if="tableSettings.refresh">
                    <b-button size="sm" class="mr-2">
                      <i class="fa fa-sync"></i> Refresh
                    </b-button>
                  </template>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  class="m-2"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                >
                  <template v-if="tableSettings.filter">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter.thirdTab"
                        type="search"
                        placeholder="Type to Search"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          :disabled="!filter.thirdTab"
                          @click="filter.thirdTab = ''"
                          >Clear
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :per-page="tableSettings.perPage"
              :current-page="tableSettings.currentPage"
              :filter="filter.thirdTab"
              :busy="isBusy"
              striped
              bordered
              show-empty
              hover
              :items="RejectedTraineeUserList"
              :fields="fields"
            >
              <template v-slot:cell(actions)="row">
                <b-button-group size="sm">
                  <b-button
                    @click="onViewDetails(row.item)"
                    variant="info"
                    size="sm"
                  >
                    View
                  </b-button>
                </b-button-group>
              </template>
            </b-table>

            <b-pagination
              v-if="tableSettings.row > tableSettings.perPage"
              class="pl-0"
              v-model="currentPage"
              :total-rows="tableSettings.rows"
              :per-page="tableSettings.perPage"
            ></b-pagination>
          </b-tab>
          <!--rejected-->
        </b-tabs>
      </template>
    </KTCard>

    <trainee-user-details-modal></trainee-user-details-modal>
    <trainee-user-pre-registration-modal></trainee-user-pre-registration-modal>
    <trainee-permission-modal></trainee-permission-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import {
  GET_TRAINEE_USER_REG_PENDING_LIST,
  CREATE_TEMP_TRAINEE_USER_REGISTRATION,
  GET_TRAINEE_USER_REG_REJECTED_LIST,
  GET_TRAINEE_USER_REG_APPROVED_LIST,
  APPROVE_TRAINEE_USER_REGISTRAION,
  REJECT_TRAINEE_USER_REGISTRAION,
} from "@/core/services/store/modules/clientadmin/trainee.module";

import { validationMixin } from "vuelidate";
import { email, required, sameAs } from "vuelidate/lib/validators";

import TraineePermissionModal from "@/view/pages/clientadmin/traineeuser/modals/TraineePermissionModal";
import SystemHelper from "@/core/services/systemhelper.service";
import TraineeUserPreRegistrationModal from "@/view/pages/clientadmin/traineeuser/modals/TraineeUserPreRegistrationModal";
import TraineeUserDetailsModal from "@/view/pages/clientadmin/traineeuser/modals/TraineeUserDetailsModal";
// import { ADD_BODY_CLASSNAME } from "@/core/services/store/modules/htmlclass.module";

export default {
  mixins: [validationMixin],
  components: {
    TraineeUserDetailsModal,
    TraineeUserPreRegistrationModal,
    TraineePermissionModal,
  },
  mounted() {
    this.isBusy = true;

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Client Admin" },
      { title: "Manage User" },
    ]);

    const vuexActions = [
      {
        action: GET_TRAINEE_USER_REG_PENDING_LIST,
        payload: this.defaultPayload,
      },
      {
        action: GET_TRAINEE_USER_REG_REJECTED_LIST,
        payload: this.defaultPayload,
      },
      {
        action: GET_TRAINEE_USER_REG_APPROVED_LIST,
        payload: this.defaultPayload,
      },
    ];
    this.dispatchAllActions(vuexActions).then(() => {
      this.isBusy = false;
    });
  },
  validations: {
    form: {
      company_id: { required },
      trainee_username: { required },
      trainee_email: { required, email },
      trainee_phone_number: { required },
      trainee_ic_number: { required },
      trainee_password: { required },
      trainee_password_confirmation: {
        sameAsPassword: sameAs("trainee_password"),
      },
    },
  },
  computed: {
    tableSettings() {
      let perPage = 20;
      let listLength = 100;
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
      };
    },
    PendingTraineeUserList() {
      const list = this.$store.state.clientadmin_trainee
        .traineeUserRegPendingList;
      if (list && list.hasOwnProperty("data")) {
        return list.data;
      } else return [];
    },
    RejectedTraineeUserList() {
      const list = this.$store.state.clientadmin_trainee
        .traineeUserRegRejectedList;
      if (list && list.hasOwnProperty("data")) {
        return list.data;
      } else return [];
    },
    ApprovedTraineeUserList() {
      const list = this.$store.state.clientadmin_trainee
        .traineeUserRegApprovedList;

      if (list && list.hasOwnProperty("data")) {
        return list.data;
      } else return [];
    },
  },
  data() {
    return {
      isBusy: false,
      selectedTrainee: null,
      form: {
        company_id: "",
        trainee_username: "",
        trainee_email: "",
        trainee_ic_number: "",
        trainee_phone_number: "",
        trainee_password: "",
        trainee_password_confirmation: "",
      },
      filter: {
        firstTab: null,
        secondTab: null,
        thirdTab: null,
      },
      currentPage: 1,
      fields: [
        {
          key: "trainee_username",
          label: "Username",
          sortable: false,
        },
        {
          key: "trainee_fullname",
          label: "Full Name",
          sortable: false,
        },
        {
          key: "trainee_email",
          label: "Email",
          sortable: false,
        },
        {
          key: "created_at",
          label: "Created On",
          sortable: false,
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-right",
        },
      ],
    };
  },

  methods: {
    onApprove(trainee) {
      let payload = { ref_id: trainee.ref_id };
      console.log(payload);
      SystemHelper.confirmationDialogHandler(
        { title: "Confirmation", html: "Approve User?" },
        { action: APPROVE_TRAINEE_USER_REGISTRAION, payload: payload },
        [
          {
            action: GET_TRAINEE_USER_REG_PENDING_LIST,
            payload: this.defaultPayload,
          },
          {
            action: GET_TRAINEE_USER_REG_APPROVED_LIST,
            payload: this.defaultPayload,
          },
        ],
        null
      );
    },
    onReject(trainee) {
      let payload = { ref_id: trainee.ref_id };
      console.log(payload);
      SystemHelper.confirmationDialogHandler(
        { title: "Confirmation", html: "Reject User?" },
        { action: REJECT_TRAINEE_USER_REGISTRAION, payload: payload },
        [
          {
            action: GET_TRAINEE_USER_REG_PENDING_LIST,
            payload: this.defaultPayload,
          },
          {
            action: GET_TRAINEE_USER_REG_REJECTED_LIST,
            payload: this.defaultPayload,
          },
        ],
        null
      );
    },
    tabChange() {
      this.filter = {
        firstTab: null,
        secondTab: null,
        thirdTab: null,
      };
    },
    onViewDetails(rowItem) {
      this.selectedTrainee = rowItem;

      this.openModal("modalTraineeUserDetails");
    },
    onPermission(trainee) {
      this.selectedTrainee = trainee;
      this.openModal("modalTraineePermission");
    },
    onRemove(trainee) {
      // assign selected trainee
      this.selectedTrainee = trainee;

      // open permission modal
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.submitForm();
    },
    submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      } else {
        console.log("submit");

        let payload = this.form;
        SystemHelper.confirmationDialogHandler(
          { title: "Confirmation", html: "Add User?" },
          { action: CREATE_TEMP_TRAINEE_USER_REGISTRATION, payload: payload },
          null,
          null
        );
      }
    },
  },
};
</script>
